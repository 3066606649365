export const isProductionBuild = (): boolean => {
  return process.env.NODE_ENV === 'production';
};

// TODO: buildが'production'かつ、applicationの本番
export const isProduction = (): boolean => {
  return isProductionBuild() && process.env.NEXT_PUBLIC_PRODUCTION === 'true';
};

export const isMarketplaceOpen = (): boolean => {
  return process.env.NEXT_PUBLIC_MARKETPLACE_COMING_SOON !== 'true';
};
