import { AnalyticsBrowser } from '@segment/analytics-next';

import { SegmentTrackData } from './events';

class Analyzer {
  private static analytics: AnalyticsBrowser;

  public static init(segmentWriteKey: string): void {
    Analyzer.analytics = AnalyticsBrowser.load({
      writeKey: segmentWriteKey,
      cdnURL: 'https://aotl-seg.anique.jp',
    });
  }

  public static page(path: string): void {
    if (!Analyzer.analytics) {
      return;
    }

    console.log('Analyzer:page', path);
    Analyzer.analytics.page({
      path,
    });
  }

  // userId: auth0ユーザーのsub, userIdとemailでidentify event実行
  public static identify(userId: string, email: string | null): void {
    if (!Analyzer.analytics) {
      return;
    }

    console.log('Analyzer:identify', userId);
    Analyzer.analytics.identify(userId, { email });
  }

  public static track(data: SegmentTrackData | null): void {
    if (!Analyzer.analytics || !data) {
      return;
    }

    const { eventName, payload } = data;
    console.log('Analyzer:track', eventName, payload);
    Analyzer.analytics.track(eventName, payload);
  }
}

export default Analyzer;
