import 'nprogress/nprogress.css';
import '../styles/main.css';

import type { GetLayout, NextPageWithLayout } from 'next';
import { AppProps } from 'next/app';
import { Router } from 'next/router';
import NProgress from 'nprogress';
import { useEffect } from 'react';
import Analyzer from '../libs/ga';
import {isProduction} from "../libs/env";
import Crisp from "../components/scripts/crisp";

if (process.env.NEXT_PUBLIC_ANALYTICS_SEGMENT_WRITE_KEY) {
  Analyzer.init(process.env.NEXT_PUBLIC_ANALYTICS_SEGMENT_WRITE_KEY);
}

NProgress.configure({ showSpinner: false });

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const defaultGetLayout: GetLayout = (page) => page;

type MyAppProps<P = unknown> = AppProps<P> & {
  Component: NextPageWithLayout<P>;
};

const MyApp = ({ Component, pageProps, router }: MyAppProps): JSX.Element => {
  const getLayout = Component.getLayout || defaultGetLayout;

  useEffect(() => {
    if (router.asPath) {
      Analyzer.page(router.asPath);
    }
  }, [router.asPath]);

  return (
      <>
        {isProduction() && <Crisp/>}
        {getLayout(<Component {...(pageProps ?? {})} />)}
      </>
  );
};

export default MyApp;
