import Script from 'next/script';

const Crisp = (): JSX.Element => {
  return (
    <Script
      id="crisp"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
      window.$crisp=[];window.CRISP_WEBSITE_ID="2d17beed-5f85-411b-8760-eb430ee68b3a";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();
    `,
      }}
    />
  );
};

export default Crisp;
